
import KpiTableCampaign from '../components/Event/KpiTableCampaign.vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import { PostRequest, GetRequest } from '@/util/http';
// const columns = [
//   {
//     name: 'week',
//     required: true,
//     label: 'Week',
//     align: 'left',
//     field: row => row.week,
//     sortable: true
//   },
//   { name: 'sales', align: 'left', label: 'Sales', field: 'sales', sortable: true },
//   { name: 'traffic', align: 'left', label: 'Traffic', field: 'traffic', sortable: true },
//   { name: 'transaction', align: 'left', label: 'Transaction', field: 'transaction', sortable: true },
//   { name: 'passersby', align: 'left', label: 'Passers By', field: 'passersby', sortable: true },
//   { name: 'capturerate', align: 'left', label: 'Capture Rate', field: 'capturerate', sortable: true },
//   { name: 'conversion', align: 'left', label: 'Conversion', field: 'conversion', sortable: true },
// ]


export default {
  components: { KpiTableCampaign },
  data () {
    return {
      widthd: false,
      kpiTableVisible: false,
      filter: {
        period: {},
        // {
        //   start: new Date(), // Jan 16th, 2018
        //   end: new Date() // Jan 19th, 2018
        // },
        store: '',
        campaign: '',
        location: '',
        group: 'Group A',
      },
      filterS: {
        period: {
          start: new Date(), // Jan 16th, 2018
          end: new Date() // Jan 19th, 2018
        },
        store: '',
        campaign: '',
        location: '',
        group: 'Group A',
      },
      filterC: {
        period: {
          start: new Date(), // Jan 16th, 2018
          end: new Date() // Jan 19th, 2018
        },
        store: '',
        campaign: '',
        location: '',
        group: 'Group B',
      },
      tab: 'performance',
      cType: 'table',
      selectedRange: {},
      categoryOption: [],
      storeOptions: []
    };
  },
  computed: {
    ...mapState('store', ['stores']),
  },
  mounted () {

    this.widthd = this.$refs.ele3.clientWidth;

  },
 async created (){
    GetRequest('http://localhost:3000/categories',{},{ headers: null })
        .then(response => response)
        .then(data => {
          this.categoryOption = data
        });

        await this.fetchStores();
      this.storeOptions = this.stores?.stores?.map(
        store => {
              return {
                label: store.storeCode + ' -  ' + store.name,
                value: store.storeCode
              };
            }
      );
  },
  methods: {
    ...mapActions('store', ['fetchStores']),
    // storeOptions
    //
    loadDataComparison() {
      this.filter.period  =  this.selectedRange;
    },
    loadDataPerformance() {// i think put it into mounted

      this.kpiTableVisible = false;
      this.filter.period  =  this.selectedRange;
      // localStorage.setItem('startDateCampaign',this.filter.period.start.toString());
      // localStorage.setItem('endDateCampaign',this.filter.period.end.toString());
      // localStorage.setItem('storeCodesList',this.filter.location.toString());
      // localStorage.setItem('daysOfWeekList','monday,tuesday,wednesday,thursday,friday,saturday,sunday');
      this.kpiTableVisible = true;
    },
    updateDateRange (data) {
      if (data) {
        this.selectedRange = data;
      }
    }, // updateDateRange
  }
};
// saveFile: function() {
//     const data = JSON.stringify(this.arr)
//     const fs = require('fs');
//     try { fs.writeFileSync('myfile.txt', data, 'utf-8'); }
//     catch(e) { alert('Failed to save the file !'); }
// }
